<template>
  <x-dialog :proxy="createDistributeDialog">
    <el-tabs type="card" v-model="currentTab" v-if="createDistributeDialog.visible">
      <el-tab-pane label="发送给系统已有用户/用户组">
        <el-form ref="dynamicValidateForm" :model="dynamicValidateForm" :rules="rules" label-width="100px">
          <el-form-item prop="charge_money" label="面值">
            <el-input v-model="dynamicValidateForm.charge_money" :maxlength="8" style="width: 200px"></el-input>
          </el-form-item>

          <el-form-item label="短信通知" error="true">
            <el-switch active-color="#13ce66" v-model="dynamicValidateForm.is_send" />

            <div slot="error" style="line-height: 1">短信签名为：{{ shopInfo.sign_name || '暂无' }}</div>
          </el-form-item>

          <el-form-item label="选择用户">

            <div class="row no-gutters align-items-center transfer">
              <div class="col">
                <el-card shadow="hover">
                  <div slot="header" class="row no-gutters align-items-center">


                    <!-- <div class="col"></div> -->

                    <el-checkbox class="mr-3" label="按照用户" v-model="dynamicValidateForm.asUserNickname" @change="$event => {
                      dynamicValidateForm.asUserGroup = !$event
                      dynamicValidateForm.type = 1
                      this.getrightmsg = []
                      this.checkAll = false;
                      this.checkAll1 = false;
                      handleCheckAllChange(false)
                      handleCheckAllChange1(false)
                    }" />
                    <el-checkbox label="按照分组" v-model="dynamicValidateForm.asUserGroup" @change="$event => {
                      dynamicValidateForm.asUserNickname = !$event
                      dynamicValidateForm.type = 2
                      this.getrightmsg = []
                      this.checkAll = false;
                      this.checkAll1 = false;
                      handleCheckAllChange(false)
                      handleCheckAllChange1(false)
                    }" />
                  </div>

                  <template v-if="dynamicValidateForm.asUserGroup">
                    <el-checkbox-group v-model="checkedfz" @change="handleCheckedusersChange" style="height: 248px">
                      <div v-for="f in fz" class="row no-gutters align-items-center transfer-item">
                        <el-checkbox class="iblock" :label="f.value" :key="f.value">{{ f.name +
                          `(${f.people_num || 0}人)` }}</el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </template>

                  <template v-else>
                    <el-input placeholder="请输入内容，按回车键搜索" size="small" style="margin-bottom: 8px" v-model="param.keyword"
                      @change="getkeyword">
                      <!-- <i class="el-icon-search el-input__icon" slot="prefix"></i> -->
                      <el-select slot="prepend" v-model="selectValue" placeholder="请选择" style="width: 90px;">
                        <el-option v-for="item in [{
                          value: '手机号',
                          label: '手机号'
                        }, {
                          value: '账号',
                          label: '账号'
                        }]" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-input>
                    <div style="padding-left: 8.5px;background-color: #f5f7fa;">
                      <el-checkbox v-if="dynamicValidateForm.asUserNickname" :indeterminate="isIndeterminate1"
                        v-model="checkAll1" @change="handleCheckAllChange1">
                        <div style="background-color: #f5f7fa;display: flex;text-align: center;">
                          <div style="width: 86px;">用户姓名</div>
                          <div style="width: 86px;">账号</div>
                          <div style="width: 86px;">手机号</div>
                        </div>
                      </el-checkbox>
                      <el-checkbox v-else :indeterminate="isIndeterminate" v-model="checkAll"
                        @change="handleCheckAllChange">
                        <div style="background-color: #f5f7fa;display: flex;text-align: center;">
                          <div style="width: 86px;">用户姓名</div>
                          <div style="width: 86px;">账号</div>
                          <div style="width: 86px;">手机号</div>
                        </div>
                      </el-checkbox>

                    </div>
                    <el-checkbox-group v-model="checkedusers" @change="handleCheckedusersChange1">
                      <virtual-list style="height: 200px; overflow-y: auto;" :data-key="'member_id'" :data-sources="users"
                        :data-component="itemComponent" />
                    </el-checkbox-group>
                  </template>
                </el-card>
              </div>

              <div class="col-auto transfer-center-icon">
                <el-button icon="el-icon-arrow-right" circle></el-button>
              </div>

              <div class="col">
                <el-card shadow="hover" :body-style="{ height: dynamicValidateForm.asUserGroup ? '264px' : '304px' }">
                  <div slot="header" class="row no-gutters align-items-center">
                    <div class="col-auto">
                      已选{{ dynamicValidateForm.asUserNickname ? '用户' : '分组' }}：{{ getrightmsg.length +
                        `${dynamicValidateForm.asUserNickname ? '人' : '组'}` }}（排除重复）
                    </div>
                  </div>

                  <!--  <el-input-->
                  <!--    v-if="dynamicValidateForm.asUserNickname"-->
                  <!--    placeholder="请输入用户昵称"-->
                  <!--    size="small"-->
                  <!--    style="margin-bottom: 8px"-->
                  <!--  >-->
                  <!--    <i class="el-icon-search el-input__icon" slot="prefix"></i>-->
                  <!--  </el-input>-->
                  <div v-if="!dynamicValidateForm.asUserGroup">
                    <div style="padding-left: 8.5px;background-color: #f5f7fa;">
                      <el-checkbox v-if="dynamicValidateForm.asUserNickname" :value="!!getrightmsg.length"
                        @change="_ => deleteMsg(-1)">
                        <div style="background-color: #f5f7fa;display: flex;text-align: center;">
                          <div style="width: 86px;">用户姓名</div>
                          <div style="width: 86px;">账号</div>
                          <div style="width: 86px;">手机号</div>
                        </div>
                      </el-checkbox>
                      <el-checkbox v-else :indeterminate="isIndeterminate" v-model="checkAll"
                        @change="handleCheckAllChange">
                        <div style="background-color: #f5f7fa;display: flex;text-align: center;">
                          <div style="width: 86px;">用户姓名</div>
                          <div style="width: 86px;">账号</div>
                          <div style="width: 86px;">手机号</div>
                        </div>
                      </el-checkbox>

                    </div>
                  </div>
                  <virtual-list v-if="!dynamicValidateForm.asUserGroup"
                    style="height: 248px; min-height: 200px; overflow-y: auto;" @deleteMsg="deleteMsg"
                    :data-key="'member_id'" :data-sources="getrightmsg" :data-component="checkedItemComponent" />
                  <virtual-list v-else style="height: 248px; min-height: 200px; overflow-y: auto;" @deleteMsg="deleteMsg"
                    :data-key="'member_id'" :data-sources="getrightmsg" :data-component="CheckedRightItemComponent" />
                  <div class="el-form-item__error" v-if="formSelectUserError">请选择至少一个用户或用户分组！</div>
                </el-card>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="备注">
            <el-input v-model="dynamicValidateForm.reamrk" maxlength="100" placeholder="请输入备注信息，字数不超过100字" />
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="通过表格导入直接需发送的用户">
        <el-alert title="提示" type="error" :closable="false">
          1. 表格导入分为手机号导入、账号导入两种。导入时系统会校验用户重复性。<br>
          2. 假如导入文件里有错误的数据，就会全部导入失败。<br>
          3. 账号由2~16位汉字、字母、数字组成；可纯汉字、纯字母、纯数字；字母不区分大小写。
        </el-alert>

        <el-form label-width="100px">
          <el-form-item label="第一步">
            选择导入方式。请下载表格模板，并填写指定数据
            <br />
            <el-radio-group v-model="selectType" @input="clearnUploadFiles">
              <el-radio :label="0">手机号导入</el-radio>
              <el-radio :label="1">账号导入</el-radio>
            </el-radio-group>
            <br />
            <el-button type="text" @click="downTemplate(selectType)">下载表格文件</el-button>
          </el-form-item>
          <el-form-item label="第二步">
            若已填好数据，请上传模板文件
            <br />
            <upload-excel-component text="选择上传文件" type="text" size="default"
              @upFileName='e => { this.tooltipContentTwo = e }' :on-success="excelSuccess" />
            <el-tooltip v-if="tooltipContentTwo" class="item" effect="dark" :content="tooltipContentTwo"
              placement="top-start">
              <el-button class="add-camilo-file">{{ tooltipContentTwo }}<i @click="clearnUploadFiles"
                  style="margin-left:6px" class="el-icon-circle-close"></i></el-button>
            </el-tooltip>
            <div v-if="dialogVisible" style="margin-bottom: 10px;">
              <p>提示：当前文件中，存在无法正常导入的数据，请修改后重新上传文件</p>
              <a :href="importError" style="color: #007bff;cursor: pointer;"><i class="el-icon-download"></i>{{
                `《${tooltipContentTwo.split('.x')[0]}》- 问题数据_` }}{{ Date.now() / 1000 | unixToDate('yyyyMMddhhmm') }}.xlsx
              </a>
            </div>
          </el-form-item>
          <el-form-item label="短信通知" error="true">
            <el-switch active-color="#13ce66" v-model="sdimport.shopMemberVo.is_send" />
            <div slot="error" style="line-height: 1">【短信签名】充值提示：【商城名称】已为您充值x元，当前帐户余额y元。</div>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="sdimport.shopMemberVo.reamrk" maxlength="100" placeholder="请输入备注信息，字数不超过100字" />
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </x-dialog>
</template>

<script>
import * as API_Users from '@/api/users';
import { handleDownload } from '@/utils';
import UploadExcelComponent from '@/components/UploadExcel';
import { mapGetters } from 'vuex';
import { RegExp } from '@/../ui-utils';
import VirtualList from 'vue-virtual-scroll-list'
import Item from './xjffCheckItem'
import CheckedItem from './xjffCheckedItem'
import CheckedRightItem from './xjffCheckedRightItem'
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
import { handleChange } from './utils/proxy'

const fz = ['分组'];

export default {
  name: 'create-distribute',
  components: {
    XDialog,
    UploadExcelComponent,
    VirtualList
  },
  computed: {
    ...mapGetters(['shopInfo']),
  },
  data () {
    const checkChargeMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('面值不能为空'));
      }
      if (!RegExp.money.test(value)) {
        callback(new Error('请输入正确的面值金额'));
      } else if (parseFloat(value) > 99999999) {
        callback(new Error('请输入小于99999999的面值金额'));
      } else {
        callback();
      }
    };
    return {
      importError: '',//失败文件地址
      tooltipContentTwo: '',//上传文件名称
      dialogVisible: false,//失败数据显示
      submitImportCardKeysFlag: false,
      selectType: 0,//选择导入的方式
      formSelectUserError: false,
      refreshFn: () => {
      },
      currentTab: '0',
      createDistributeDialog: $xDialog.create({
        title: '充值分发',
        width: '800px',
        wrapperClass: 'recharge-distribution-dialog',
        afterDismiss: () => {
          this.importError = '';//失败文件地址
          this.tooltipContentTwo = '';//上传文件名称
          this.dialogVisible = false;//失败数据显示
          this.submitImportCardKeysFlag = false;
          this.selectType = 0;//选择导入的方式
        },
        beforeConfirm: () => {
          switch (this.currentTab) {
            case '0':
              return this.czok();
            case '1':
              return this.czok1();
          }
          return true;
        }
      }),
      sonflag: false,
      fileList: [
        {
          name: 'food.jpeg',
          url:
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        },
      ],
      sdimport: {
        shopMemberVo: {
          is_send: 0,
          reamrk: '',
        },
      },
      activeName: 'tabOne',
      checkAll: false,
      checkAll1: false,
      checkedusers: [],
      checkedfz: [],
      users: [],
      itemComponent: Item,
      checkedItemComponent: CheckedItem,
      CheckedRightItemComponent: CheckedRightItem,
      isIndeterminate: false,
      isIndeterminate1: false,
      dynamicValidateForm: {
        reamrk: '',
        is_send: false,
        type: 1,
        ids: [],
        charge_money: '',
        asUserNickname: true,
        asUserGroup: false,
      },
      input: '',
      fz: [],
      param: { keyword: '' },
      rightmsg: [],
      rightids: [],
      getrightmsg: [],
      getrightids: [],
      templateKey: {
        importMobile: ['mobile', 'charge_money'],
        importAccout: ['login_account', 'charge_money']
      },
      templateHeader: {
        importMobile: ['手机号', '金额'],
        importAccout: ['账号', '金额']
      },
      rules: {
        charge_money: [
          {
            required: true,
            validator: checkChargeMoney,
            trigger: 'blur'
          },
        ],
      },
      selectValue: '手机号'
    };
  },

  watch: {
    activeName: function (val) {
      this.activeName = val;
    },
  },

  created () {
    this.getkeyword();
    let para = {
      page_no: 0,
      page_size: 0,
    };
    API_Users.getUserlist(para).then((res) => {
      res.data.forEach((item) => {
        item.name = item.group_name;
        item.value = [item.group_id, item.group_name, item.people_num].join(',');
      });
      this.fz = res.data;
    });
    this.handleClick();
    // 订阅删除事件
    handleChange.subscribe({
      next: (index) => this.deleteMsg(index)
    })
  },
  methods: {
    //删除已上传文件
    clearnUploadFiles () {
      this.dialogVisible = false;
      //删除在线发放的已上传文件
      this.tooltipContentTwo = ''
      this.sdimport.shopMemberVo.member_charge_list = [];
    },
    show (refreshFn) {
      this.refreshFn = refreshFn;
      // 初始化窗口数据
      // --- 初始化勾选状态
      this.checkAll = false;
      this.checkAll1 = false;
      this.handleCheckAllChange(false);
      this.handleCheckAllChange1(false);
      // --- 初始化右侧显示数据
      this.rightmsg = [];
      this.rightids = [];
      this.chuan();
      // --- 初始化表单数据，记录
      this.dynamicValidateForm = {
        reamrk: '',
        is_send: false,
        type: 1,
        ids: [],
        charge_money: '',
        asUserNickname: true,
        asUserGroup: false,
      };
      // --- 初始化el-tab-pane的位置位于第一个标签下
      this.currentTab = '0';
      this.createDistributeDialog.display();
    },
    //文件上传成功回调
    excelSuccess ({ filename, header, results }) {
      if (this.selectType == 0) {//手机号导入
        if (!header.some(item => item == '手机号') || !header.some(item => item == '金额')) {
          this.tooltipContentTwo = '';
          this.$alert('文件格式不正确，请重新上传', '提示')
          return
        }
      } else if (this.selectType == 1) {//账号导入
        if (!header.some(item => item == '账号') || !header.some(item => item == '金额')) {
          this.tooltipContentTwo = '';
          this.$alert('文件格式不正确，请重新上传', '提示')
          return
        }
      }
      let resultsList = [];
      results.forEach((list) => {
        this.templateKey[this.selectType == 1 ? 'importAccout' : 'importMobile'].forEach((item, index) => {
          list = JSON.parse(
            JSON.stringify(list).replace(this.templateHeader[this.selectType == 1 ? 'importAccout' : 'importMobile'][index], item)
          );
        });
        resultsList.push(list);
      });
      this.sdimport.shopMemberVo.member_charge_list = resultsList;
    },
    //下载导入模板
    downTemplate (type) {
      let templateMobile = {//手机号导入
        mobile: '',
        charge_money: '',
      },
        templateAccout = {//账户密码导入
          login_account: '',
          charge_money: '',
        };
      handleDownload(
        [
          type == 0 ? templateMobile : templateAccout
        ],
        type == 0 ? this.templateHeader.importMobile : this.templateHeader.importAccout,
        type == 0 ? this.templateKey.importMobile : this.templateKey.importAccout,
        '用户充值分发导入模板'
      );
    },

    handleClick (tab, event) {
      if (this.activeName === 'tabOne') {
        this.dynamicValidateForm.type = 1;
      } else {
        this.dynamicValidateForm.type = 2;
      }
      // this.dynamicValidateForm.type = tab.index + 1;
    },
    chuan () {
      if (this.rightmsg.length === 0) {
        this.getrightmsg = [];
        this.getrightids = [];
      }
      this.getrightmsg = this.rightmsg;
      this.getrightids = this.rightids;
    },
    getkeyword () {
      this.param.is_salesman = 0;
      API_Users.dpgetMemberList(this.param).then((res) => {
        res.forEach((item) => {
          item.value = [item.member_id, item.member_name, item.login_account, item.mobile].join(',');
        });
        this.users = res;
      });
    },
    deleteMsg: function (index) {
      // 删除右侧显示的数据
      if (index === -1) {
        if (this.dynamicValidateForm.asUserNickname) this.checkedusers = [], this.isIndeterminate1 = true, this.checkAll1 = false;
        else this.checkedfz = [], this.isIndeterminate = true, this.checkAll1 = false;
        this.rightmsg = [], this.rightids = [], this.chuan()
        this.isIndeterminate1 = false
        return
      };
      // 删除单个右侧显示的数据
      if (this.dynamicValidateForm.asUserNickname) {
        this.checkedusers.splice(index, 1);
        this.isIndeterminate1 = true;
      } else {
        this.checkedfz.splice(index, 1);
        this.isIndeterminate = true;
      }
      this.rightmsg.splice(index, 1);
      this.rightids.splice(index, 1);
      // this.isIndeterminate1 =
      //   this.checkedusers > 0 && this.checkedusers < this.users.length;
      this.isIndeterminate1 = this.rightids.length > 0 && this.rightids.length < this.users.length;
      this.chuan();
    },
    czok () {//系统发放
      return new Promise(resolve => {
        this.$refs['dynamicValidateForm'].validate((valid) => {
          if (valid) {
            this.dynamicValidateForm.ids = this.getrightids;
            this.formSelectUserError = this.dynamicValidateForm.ids.length === 0;
            if (this.formSelectUserError) {
              return resolve(false);
            }
            if (this.dynamicValidateForm.is_send === true) {
              this.dynamicValidateForm.is_send = 1;
            } else {
              this.dynamicValidateForm.is_send = 0;
            }
            API_Users.dpAddCharge(
              this.dynamicValidateForm.ids,
              this.dynamicValidateForm
            ).then((res) => {
              this.refreshFn();
              resolve(true);
            });
          } else {
            resolve(false);
          }
        });
      });
    },
    czok1 () {//表格导入充值
      return new Promise(resolve => {
        // 如何上传的信息有错误的 那么将不能再点击
        if (this.dialogVisible) return
        if (this.tooltipContentTwo == '') {
          return this.$message.error('请上传文件')
        }
        //防止重复提交——开始
        if (this.dialogVisible || this.submitImportCardKeysFlag) return
        this.submitImportCardKeysFlag = true
        //结束
        if (this.sdimport.shopMemberVo.is_send === true) {
          this.sdimport.shopMemberVo.is_send = 1;
        } else {
          this.sdimport.shopMemberVo.is_send = 0;
        }
        this.sdimport.shopMemberVo.is_recharge = 1;
        this.sdimport.shopMemberVo.import_type = this.selectType;
        API_Users.importSXChargeMoney(
          JSON.stringify(this.sdimport.shopMemberVo)
        ).then((res) => {
          this.submitImportCardKeysFlag = false
          if (res.file !== '') {
            this.dialogVisible = true;
            if (res.message) {
              this.$message.error(res.message);
            } else {
              this.importError = res.file;
            }
            resolve(false);
          } else {
            this.$message.success(res.message);
            this.refreshFn();
            resolve(true);
          }
        });
      })
    },
    handleRemove (file, fileList) {
    },
    handlePreview (file) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleCheckAllChange (val) {

      const arr = val ? this.fz : [];
      this.checkedfz = [];
      arr.map((item) => {
        this.checkedfz.push(item.value);
      });
      this.valueSplit(this.checkedfz);
      this.chuan();
      this.isIndeterminate = false;

    },
    handleCheckAllChange1 (val) {
      const arr = val ? this.users : [];
      this.checkedusers = [];
      arr.map((item) => {
        this.checkedusers.push(item.value);
      });
      this.valueSplit(this.checkedusers);
      this.isIndeterminate1 = false;
      this.chuan();
    },

    handleCheckedusersChange (value) {
      this.valueSplit(value);
      let arr = value;
      this.checkedfz = arr;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fz.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fz.length;
      this.chuan();
    },
    handleCheckedusersChange1 (value) {
      this.valueSplit(value);
      let arr = value;
      this.checkedusers = arr;
      let checkedCount = value.length;
      this.checkAll1 = checkedCount === this.users.length;
      this.isIndeterminate1 =
        checkedCount > 0 && checkedCount < this.users.length;
      this.chuan();
    },
    valueSplit (value) {
      this.rightids = [];
      this.rightmsg = [];
      value.forEach((item) => {
        let arr = (item + '').split(',');
        this.rightids.push(arr[0]);
        // this.rightmsg.push(arr[1]);
        this.rightmsg.push({
          'member_id': arr[0],
          'member_name': arr[1] !== 'null' ? arr[1] : '',
          'login_account': arr[2] !== 'null' ? arr[2] : '',
          'mobile': arr[3] !== 'null' ? arr[3] : '',
        });
      });
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain (item) {
      const index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain () {
      this.dynamicValidateForm.domains.push({
        value: '',
        key: Date.now(),
      });
    },
  },
};
</script>

<style lang="scss">
.recharge-distribution-dialog {
  .transfer {
    .el-card__header {
      height: 40px;
      line-height: 40px;
      background: #f5f7fa;
      margin: 0;
      padding: 0 15px;
      color: #333;

      .el-checkbox {
        line-height: 1;
        margin: 0;
      }

      .row {
        height: 100%;
      }
    }

    .el-card__body {
      padding: 8px;

      .el-checkbox {
        line-height: 2;
        margin: 0;
      }

      .transfer-item {
        padding-left: 7px;
      }
    }

    .transfer-center-icon {
      .el-button {
        border: none;
        pointer-events: none;
      }
    }
  }

  .el-form-item.is-error {
    .el-card__body {
      .el-input__inner {
        border-color: #DCDFE6 !important;
      }
    }
  }
}

.el-form-item {
  margin-bottom: 0;
}

.el-form-item:last-of-type {
  margin-bottom: 22px;
}
</style>
