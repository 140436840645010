<template>
  <div class="row no-gutters align-items-center transfer-item">
    <!-- 因为是用value传值 跟其他会有冲突 所以member_name 是几组 login_account 是几人 -->
    <el-checkbox :key="source.value" :value="true" @change="handleItemClick" :label="source.value">{{
      `${source.member_name}(${source.login_account}人)` }}</el-checkbox>
  </div>
</template>

<script>
import { handleChange } from './utils/proxy'
export default {
  name: 'item-component',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    isLeft: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleItemClick () {
      handleChange.next(this.index)
      // this.$emit('deleteMsg', this.index);
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  // background-color: #000;
  font-size: 12px;

  div {
    width: 86px;
    height: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: 1px solid #e9ebef;
    box-sizing: border-box;
    margin-bottom: -1px;
  }
}

.double {
  background-color: #fafafa;
}

.div_hover {
  &:hover {
    background-color: #f5f7fa;
  }
}
</style>