<template>
  <!-- <div class="row no-gutters align-items-center transfer-item">
    <el-checkbox :key="index" :value="true" @change="handleItemClick">{{ source.nickname }}</el-checkbox>
  </div> -->
  <div style="display: flex; border: 1px solid #e9ebef;" :class="[index % 2 ? 'double' : '', 'div_hover']">
    <div class="row no-gutters align-items-center transfer-item ">
      <el-checkbox :key="index" :value="true" @change="handleItemClick">
        {{ '' }}
      </el-checkbox>
    </div>
    <div class="content">
      <div> {{ source.member_name }}</div>
      <div>{{ source.login_account }}</div>
      <div> {{ source.mobile }}</div>
    </div>
  </div>
</template>

<script>
import { handleChange } from './utils/proxy'
export default {
  name: 'item-component',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleItemClick () {
      handleChange.next(this.index)
      // this.$emit('deleteMsg', this.index);
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  // background-color: #000;
  font-size: 12px;

  div {
    width: 86px;
    height: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: 1px solid #e9ebef;
    box-sizing: border-box;
    margin-bottom: -1px;
  }
}

.double {
  background-color: #fafafa;
}

.div_hover {
  &:hover {
    background-color: #f5f7fa;
  }
}
</style>