<template>
  <div class="userAdimin">
    <en-table-layout
      :tableData="tableData.data"
      @selection-change="handleSelectionChange"
    >
      <template slot="toolbar">
        <div class="col toolbar-title">
          用户充值管理
        </div>
        <div class="col-auto">
          <el-button type="primary" size="small" @click="newDistribute">新建充值分发</el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="编号" width="80">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + (tableData.page_no - 1) * (tableData.page_size) }}
          </template>
        </el-table-column>

        <el-table-column label="生成方式" width="200">
          <template slot-scope="scope">{{ scope.row.charge_type === 1 ? '系统生成' : '表格导入' }}</template>
        </el-table-column>

        <el-table-column prop="total_money" label="总价值" width="100"></el-table-column>

        <el-table-column prop="charge_num" label="发放总人数" width="100"></el-table-column>

        <el-table-column prop="create_time" label="发放时间" width="150">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate }}</template>
        </el-table-column>

        <el-table-column prop="reamrk" label="备注信息" min-width="200"></el-table-column>

        <el-table-column label="操作" show-overflow-tooltip width="80">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleEdit(scope.$index, scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="inform.page_no"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :page-size="inform.page_size"
        :total="total"
      />
    </en-table-layout>

    <create-distribute ref="createDistribute" />
  </div>
</template>
<script>
import CreateDistribute from './create-distribute';
import * as API_Users from '@/api/users';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  components: {
    EnTableLayout,
    CreateDistribute,
  },
  data() {
    return {
      total: 0,
      flag: false,
      parentmsg: '',
      formInline: {
        user: '',
        region: ''
      },
      inform: {
        page_size: 20,
        page_no: 1
      },
      page: {},
      tableData: {},
      multipleSelection: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    newDistribute() {
      this.$refs.createDistribute.show(() => this.getList());
    },
    getList() {
      API_Users.chargeList(this.inform).then(res => {
        this.total = res.data_total;
        this.tableData = res;
      });
    },
    handleSizeChange(val) {
      this.inform.page_size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.inform.page_no = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit(index, row) {
      this.$router.push({
        path: '/users/chongMsg',
        query: {charge_id: row.charge_id}
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.xff {
  position: absolute;
  top: 0;
  left: 0;
}

.posi {
  z-index: 99;
  position: absolute;
  left: 85px;
  top: 100px;
}

.fenye .block .el-pagination {
  padding: 10px 0px;
  text-align: center;
}

.userAdimin {
  position: relative;
  background-color: #fff;
}

/* .search {
  padding: 10px;
  width: 100%;
} */
/* .search .el-form-item__content {
  width: 145px;
}
.search .el-form-item__content .el-input__inner {
  height: 26px;
}
.search .el-button--primary {
  height: 21px;
}
.search .el-form-item {
  margin-bottom: 0px;
}
.search .btn3 .el-form-item__content {
  width: 400px;
}
.search .el-button {
  line-height: 0;
}
.search .el-form {
  margin-bottom: 20px;
} */
.tableList .is-leaf {
  text-align: center;
}

.tableList .el-table__body .el-table__row td .cell {
  text-align: center;
}

.tableList .el-table_1_column_1 .cell .el-checkbox__inner {
  background-color: #e6ecf7;
}
</style>
